body {
  margin: 0;
  font-family: Arial, sans-serif;
  transition: background-color 0.5s ease;
}

.App {
  text-align: center;
}

.main-content {
  background-color: #00b65f; /* Green background */
  color: white;
  padding: 20px;
  min-height: 100vh; /* Ensure the main content area takes up the full viewport height */
  display: flex;
  flex-direction: column;
}

.header {
  background-color: #00bf63; /* Green background */
  margin-top: -10px;
  padding: 15px 20px; /* Add some padding to the header */
  display: flex; /* Use flexbox for layout */
  justify-content: space-between; /* Space between logo and app store button */
  align-items: center; /* Center items vertically */
}

.content-wrapper {
  display: flex;
  justify-content: space-between; /* Space out logo and app store button */
  align-items: center; /* Center items vertically */
  max-width: 1200px; /* Set a max-width for larger screens */
  margin: 0 auto; /* Center the content on larger screens */
  width: 100%; /* Full width */
  padding: 0 20px; /* Add padding for mobile */
  box-sizing: border-box; /* Include padding in width calculations */
}

.logo {
  height: 1200px; /* Maintain aspect ratio */
}

@media (max-width: 768px) {
  .logo {
    height: 120px; /* Maintain aspect ratio */
  }
}

.header-right {
  display: flex;
  align-items: center;
}

.app-store-button {
  height: 50px; /* Smaller height */
  width: auto; /* Maintain aspect ratio */
  transition: filter 0.3s ease; /* Keep the transition for hover effects */
}

.app-store-button.blurred {
  filter: blur(3px) brightness(1.5);
  pointer-events: none;
}

nav ul {
  display: flex;
  list-style-type: none;
  gap: 20px;
  margin: 0;
  padding: 0;
}

.nav-link {
  color: white;
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  transition: all 0.3s ease;
  padding: 5px 10px;
  border-radius: 5px;
}

.nav-link:hover {
  text-decoration: underline;
}

.nav-link.active {
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0 0 50px rgba(255, 255, 255, 0.5);
}

nav ul li {
  margin-left: 20px;
}

nav ul li a {
  color: white; /* White text */
  text-decoration: none;
  font-size: 16px;
}

.menu-button {
  display: block;
  background: none;
  border: none;
  color: white; /* White text */
  font-size: 44px;
  cursor: pointer;
  margin-left: 100px;
}

.hero {
  background-color: #00bf63;
  color: white;
  padding: 20px 0;
  height: 50vh; /* Reduced height */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -40px;
  overflow: hidden; /* Prevent video from overflowing */
}

.hero-content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero-video {
  max-width: 90%;
  max-height: 90%;
  width: auto;
  height: auto;
  background: transparent; /* Ensure the video background is transparent */
  box-shadow: none; /* Ensure no shadows */
  -webkit-box-shadow: none; /* Ensure no shadows on Safari */
  border: none; /* No border */
  outline: none; /* No outline */
  object-fit: contain; /* Ensure the video fits properly */
}

/* Override for mobile devices */
@media (max-width: 768px) {
  .hero {
      background-color: #00b65f; /* Mobile-friendly color */
      height: 30vh; /* Reduced height */
      margin-top: -40px;
  }
  .header {
    background-color: #00b65f; /* Green background */
  }
  .app-store-button {
    height: 30px; /* Smaller height */
  }
}

.hero h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

.hero h2 {
  font-size: 48px;
  margin-bottom: 20px;
}

.hero p {
  font-size: 18px;
}

.hero-card {
  max-width: 800px;
  margin: 0 auto;

  border-radius: 20px;
  overflow: hidden;
  margin-top: 30px;
  box-shadow: none; /* Remove any shadow on the hero card */
  background-color: transparent; /* Make the background transparent to blend with the site background */
}

.hero-image {
  max-width: 100%; /* Allow the image to take full width of its container */
  max-height: 80vh; /* Limit the height to 80% of the viewport height */
  object-fit: contain;
  margin: 0 auto; /* Center the image horizontally */
  width: 100%;
  height: auto;
  display: block;
}

.hero-card p {

  padding: 10px;
  font-size: 24px;
  margin: 0;
}

.hero-description {
  max-width: 800px;
  margin: 50px auto 0;
}

h3 {
  font-size: 36px;
}

.hero-description p {
  font-size: 18px;
  line-height: 1.5;
}

.hero-image-placeholder {
  background-color: #ccc;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: #666;
}

/* If the logo is still too small, you can increase it further */
@media (min-width: 768px) {
  .logo {
    height: 180px; /* Increased from 80px to 180px for larger screens */
  }
}

/* Responsive adjustments */
@media (max-width: 1023px) {
  .content-wrapper {
    padding: 0 20px;
  }

  .header {
    flex-wrap: wrap;
  }

  nav {
    order: 3;
    width: 100%;
    margin-top: 20px;
  }

  .menu-button {
    display: block;
  }

  .hero h2 {
    font-size: 48px;
  }
}

@media (max-width: 767px) {
  .logo {
    height: 100px;
  }

  .header {
    padding: 0px 0px;
  }

  nav ul {
    flex-direction: column;
    gap: 15px;
  }

  .hero h2 {
    font-size: 36px;
  }
}

/* Add this new class for content width control */
.content-wrapper {
  max-width: 1800px; /* Increased from 1400px */
  margin: 0 auto;
  padding: 0 40px; /* Reduced side padding */
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 768px) {
  .content-wrapper {
    padding: 0 20px;
  }

  nav {
    display: none; /* Hide navigation on mobile */
  }

  .menu-button {
    margin-left: 0;
  }
}

/* Add responsive margins */
@media (min-width: 768px) {
  .hero {
    padding: 0 40px; /* Increase padding for larger screens */
  }
}

@media (min-width: 1024px) {
  .hero {
    padding: 0 80px; /* Further increase padding for even larger screens */
  }
}

.footer {
  background-color: #00BF63;
  color: white;
  padding: 40px 0 20px;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

.footer-logo img {
  height: 200px;
}

.footer-nav ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.footer-nav li {
  margin-right: 20px;
  margin-bottom: 10px;
}

.footer-nav a {
  color: white;
  text-decoration: none;
  font-size: 14px;
}

.social-links a {
  color: white;
  font-size: 20px;
  margin-left: 15px;
}

.footer-bottom {
  text-align: center;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.footer-bottom p {
  font-size: 12px;
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .footer-nav ul {
    justify-content: center;
  }

  .social-links {
    margin-top: 20px;
  }
}

.features {
  background-color: white; /* Solid white background */
  padding: 60px 0;
  transition: background 1s ease; /* Smooth transition for background color */
}

.feature-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
}

.feature-item {
  flex: 1;
  margin: 10px; /* Add some margin between cards */
  min-width: 300px; /* Ensure a minimum width for cards */
}

.card {
  border-radius: 30px;
  overflow: hidden; /* Ensure the image fits within the card */
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: opacity 1s ease; /* Smooth transition for opacity */
}

.card-image {
  height: 200px; /* Set a fixed height for the image */
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-image img {
  width: 100%; /* Make the image fill the width */
  height: auto; /* Maintain aspect ratio */
}

.card-addition-text {
  padding: 20px; /* Add padding for text */
  text-align: center; /* Center text */
  display: flex; /* Use flex to center the text */
  flex-direction: column; /* Stack text vertically */
  justify-content: center; /* Center text vertically */
  align-items: center; /* Center text horizontally */
}

.card-addition {
  background-color: white; /* Start with a white background */
  color: white;
  padding: 60px 0;
  min-height: 100vh; /* Ensure it takes full height */
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 1s ease; /* Smooth transition for background color */
}

.card-addition-content {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.blue-card {
  background-color: #0071e3; /* Blue background for the new card */
  border-radius: 30px; /* Rounded corners */
  margin-bottom: 20px; /* Space between cards */
  display: flex; /* Use flexbox for centering */
  height: 600px;
  justify-content: center; /* Center horizontally */

}

.blue-card h2 {
  color: white; /* White text color */
  font-size: 64px; /* Huge font size */
  font-weight: bold; /* Bold text */
  margin: 0; /* Remove default margin */
}

.white-square {
  background-color: white;
  border-radius: 30px;
  margin-left: 10px;
  margin-right: 10px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  height: 100%; /* Ensure the card takes full height */
}

.card {
  border-radius: 30px;
  overflow: hidden; /* Ensure the image fits within the card */
  position: relative; /* Positioning for the image */
}

.card-image {
  background-color: #00BF63; /* Green background for the top half */
  height: 50%; /* Adjust height as needed */
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-image img {
  width: 100%; /* Make the image fill the width */
  height: auto; /* Maintain aspect ratio */
}

.card-addition-text h3 {
  font-size: 38px; /* Increased from 36px */
  color: #0071e3;
  text-align: center; /* Center text */
}

.card-addition-text {
  padding: 20px; /* Add padding for text */
  text-align: center; /* Center text */
}

.card-addition-text h2 {
  font-size: 64px; /* Increased font size */
  margin-bottom: 10px; /* Increased from 10px */
  color: #1d1d1f;
  line-height: 1.1; /* Improve readability */
  text-align: center; /* Center text */
}



.blue-card-test {
  text-align: left; 
  margin-left: 50px; 
  margin-top: 50px; 
  width: 650px; /* Default width for desktop */
  font-size: 90px; /* Default font size for desktop */
}

.setup-button-wrapper {
  position: relative; /* Positioning for the overlay */
  display: flex; /* Center the button */
  justify-content: center; /* Center the button */
  align-items: center; /* Center vertically */
}

.setup-button {
  height: 100px; /* Default height for desktop */
  width: auto; /* Maintain aspect ratio */
  transition: filter 0.3s ease; /* Keep the transition for hover effects */
}

@media (max-width: 768px) {
  .setup-button {
    height: 50px; /* Height for mobile */
  }
}

.setup-button.blurred {
  filter: blur(3px) brightness(1.5);
  pointer-events: none; /* Disable pointer events when blurred */
}

.coming-soon-overlay {
  position: absolute; /* Position overlay on top of the button */
  top: 0; /* Align to the top */
  left: 0; /* Align to the left */
  right: 0; /* Align to the right */
  bottom: 0; /* Align to the bottom */
  display: flex;
  justify-content: center; /* Center the text */
  border-radius: 25px;
  align-items: center; /* Center the text */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  color: white; /* Text color */
  font-weight: bold; /* Bold text */
  height: 90%; /* Full height */
  width: 100%; /* Full width */
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s ease; /* Smooth transition for visibility */
}

.setup-button-wrapper:hover .coming-soon-overlay {
  opacity: 1; /* Show overlay on hover */
}

@media (max-width: 768px) {
  .card-addition-content {
    margin-left: 10px; /* Adjust padding for mobile */
    margin-right: 10px;
    padding: 0px;
  }

  .blue-card{
    height: 250px;
  }

  .blue-card-test {
    width: 100%; /* Full width on mobile */
    font-size: 35px; /* Reduced font size for mobile */
    margin-left: 15px; /* Adjust margin for mobile */
    margin-top: 20px; /* Adjust margin for mobile */
  }

  .card-addition-text h2 {
    font-size: 20px; /* Adjust font size for mobile */
  }

  .card-addition-text h3 {
    font-size: 24px; /* Increased from 36px */
    color: #0071e3;
    height: 80px;
    text-align: center; /* Center text */
  }
}

body {
  margin: 0;
  padding: 0;
  transition: background-color 0.5s ease;
}

.features {
  background: white; /* Gradient from white to a very light blue */
  padding: 60px 0;
  transition: background 1s ease; /* Smooth transition for background color */
}

.card-addition {
  background-color: white; /* Start with a white background */
  color: white;
  padding: 60px 0;
  min-height: 100vh; /* Ensure it takes full height */
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 1s ease; /* Smooth transition for background color */
}

.white-square {
  background-color: white;
  border-radius: 30px;
  padding: 60px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  height: 100%; /* Ensure the card takes full height */
}

.phone-image {
  flex: 1;
  max-width: 50%;
  display: flex; /* Use flex to align the image */
  justify-content: center; /* Center the image horizontally */
  align-items: flex-end; /* Align the image to the bottom */
}

.phone-image img {
  max-width: 100%;
  height: auto;
  display: block; /* Remove any extra space below the image */
}

.card-addition-text {
  flex: 1;
  padding: 10px;
  margin-top: -30px;
  display: flex; /* Use flex to center the text */
  flex-direction: column; /* Stack text vertically */
  justify-content: center; /* Center text vertically */
  align-items: center; /* Center text horizontally */
}

.card-addition-text h2 {
  font-size: 64px; /* Increased from 48px */
  margin-bottom: 10px; /* Increased from 10px */
  color: #1d1d1f;
  line-height: 1.1; /* Improve readability */
  text-align: center; /* Center text */
}


@media (max-width: 768px) {
  .white-square {
    flex-direction: column;
    padding: 30px;
    margin-left: 20px;
    margin-right: 20px;
    align-items: center; /* Center items on mobile */
  }

  .blue-card{
    margin-right: 20px;
    margin-left: 20px;
  }

  
  .phone-image, .card-addition-text {
    max-width: 100%;
  }
  
  .card-addition-text {
    padding-left: 0;
    padding-top: 30px;
    padding-bottom: 0; /* Remove bottom padding on mobile */
    text-align: center;
    margin-bottom: -30px;
  }

  .card-addition-text h2 {
    font-size: 28px; /* Slightly smaller on mobile */
    margin-bottom: 10px;
    margin-top: 5px;
  }
}

